// pusherUtils.js
'use strict';
import { pusher as pusherDebug } from '../resource/debug.js';
import ServiceWorkerMessenger from '../resource/ServiceWorkerMessenger.js';

const debugLog = pusherDebug.extend('log');
const debugErrorLog = pusherDebug.extend('error');

export const getPrivateUserChannel = ({ userId }) =>
  userId ? `private-enc-user@${userId}` : undefined;

const CRITICAL_CHANNEL_REGEXES = [
  /^presence-enc-client@.+/,
  /^presence-enc-user@.+/,
  /^private-enc-stream@([a-z\d]+)$/i,
  // presence-enc-stream-viewer@STREAMER_ID.PRESET.VIEWER_ID
  /^presence-enc-stream-viewer@([a-z\d]+)\.([a-z]+)\.([a-z\d]+)$/i,
  /^presence-enc-asset@([a-z\d]+)$/i,
  /^presence-enc-message@([a-z\d]+)$/i,
  /^private-enc-campaign@([a-z\d_]+)$/i,
];

/**
 * Determines if a given channel is considered critical.
 *
 * @param {object} options - The options object.
 * @param {string} options.channelName - The name of the channel to check.
 * @returns {boolean} True if the channel is critical, false otherwise.
 */
export const getIsCriticalChannel = ({ channelName }) =>
  !!CRITICAL_CHANNEL_REGEXES.find(regex => regex.test(channelName));

const getIsValidChannelName = ({ channelName }) => {
  if (channelName.includes('undefined')) {
    return false;
  }
  let result = true;
  switch (true) {
    case channelName.startsWith('private-enc-user@'): {
      const userId = channelName.replace('private-enc-user@', '');
      if (
        userId === undefined ||
        userId === 'undefined' ||
        userId.length !== 24
      ) {
        result = false;
      }
      break;
    }
    default:
      break;
  }
  return result;
};

/**
 * Update pusher channel target
 * @param {object} {target} - object with channel configs.
 * @param {string} {target.(channelName).subscriptionReference} - subscription reference for identify subscribe reason and source
 * @param {bool} {target.(channelName).shouldSubscribe} - toggle should subscribe status
 * @param {bool} {[target.(channelName).isCritical]} - is channel critical, default from getIsCriticalChannel().
 * @param {number} {[target.(channelName).expiryTimestamp]} - channels need to re-auth is created before this timestamp
 */
export const updatePusherChannelTarget = ({ target }) => {
  const log = debugLog.extend('updatePusherChannelTarget');
  const errorLog = debugErrorLog.extend('updatePusherChannelTarget');

  log('start', { target });

  const workerMessenger = ServiceWorkerMessenger.getInstance();
  if (!workerMessenger) {
    errorLog('missing worker messenger');
    return;
  }

  const currentTarget = workerMessenger.scope.pusher?.target || {};
  Object.keys(target).forEach(channelName => {
    if (!getIsValidChannelName({ channelName })) {
      errorLog('invalid channelName', { channelName });
      return;
    }
    const currentData = currentTarget[channelName] || {};
    const targetData = target[channelName];

    // cleanup extra no-subscribe references
    const subscriptionMap = Object.assign({}, currentData.subscriptionMap, {
      [targetData.subscriptionReference]: targetData.shouldSubscribe,
    });
    Object.keys(subscriptionMap)
      .filter(ref => !subscriptionMap[ref].shouldSubscribe)
      .slice(0, -8) // only keep 8 no-subscription refs
      .forEach(ref => {
        delete subscriptionMap[ref];
      });

    const subscriptionData = {
      channelName,
      isCritical:
        targetData.isCritical ??
        (currentData.isCritical || getIsCriticalChannel({ channelName })),
      subscriptionMap,
      timestamp: currentData.timestamp || Date.now(),
      expiryTimestamp: Math.max(
        targetData.expiryTimestamp || 0,
        // Date.now() to make sure new tabs get fresh channel data
        currentData.expiryTimestamp || Date.now()
      ),
    };

    workerMessenger.scope.pusher = Object.assign(
      workerMessenger.scope.pusher || {},
      {
        target: Object.assign(workerMessenger.scope.pusher?.target || {}, {
          [channelName]: subscriptionData,
        }),
      }
    );
    log('channel assigned', { channelName, subscriptionData });
  });
};
